<template>
    <div class="result second-box bgcolor">
        <Head :title="$t('home.shai')" :right="true" :show="true"></Head>
        <div class="main-box result-box">
            <div class="edit-box">
                <div class="edit-main">
                    <div class="top-one">
                        <img class="item-img" alt="" :src="obj.thumb" >
                        <div class="item-right">
                            <div class="item-right-one">{{$t('home.di')}} {{obj.qishu}} {{$t('home.qi')}}</div>
                            <h2 class="item-right-name van-ellipsis">{{obj.title}}</h2>
                            <!--  :class="lang=='cn'?'':'margintop2'" -->
                            <div class="item-right-time">
                                <p>{{$t('common.open_time')}} <span>{{obj.starttime || obj.winningTime}}</span></p>
                            </div>
                            <!-- :style="{flexDirection:lang=='en'?'column':''}" -->
                            <div class="item-right-value" >
                                <p >{{$t('common.good_value')}}:<span>${{obj.total_amount | number_format}}</span></p>
                                <p >{{$t('common.auction_price')}}:<span>${{(obj.betprice || obj.winning_price) | number_format}}</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="text-box">
                        <van-field
                            v-model="message"
                            rows="4"
                            autosize
                            :left-icon="require('@/assets/images/edit.png')"
                            type="textarea"
                            :placeholder="$t('home.tip_2')"
                            
                            />
                        <div class="add-pic">
                            <input  type="file" name="file" id="download" ref="download" style="display:none" @change="uploadFile()" />
                            <div class="photo-item" @click.stop="clicktodo" > 
                                <img :src="require('@/assets/images/add-pic.png')" alt="">
                                <p :class="lang=='cn'?'':'small-text'" >{{$t('home.add_pic')}}</p>
                            </div> 
                            <div class="photo-item" v-for="(v,i) in show_pic" :key="i"> 
                                <img class="newimg" :src="$host+v" alt="">
                            </div> 
                        </div>
                    </div>
                </div>
                <van-button class="confirm-btn" @click="submitEdit">{{$t('common.confirm')}}</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import {Toast,Notify} from "vant"
import {randomString} from "@/common"
export default {
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            imgSrc:"http://47.104.209.44/base/img/3.jpg",
            message:'',
            obj:{},
            show_pic:[]
        }
    },
    beforeRouteLeave(from,to,next){
        // 询问是否离开
        next();
    },
    methods:{
        submitEdit(){
            if(!this.message){
                 Toast(this.$t('home.tip_one'))
            }else{
                this.$ajax.submitsaidan({
                    content:this.message,
                    show_pic:this.show_pic,
                    orderid:this.$route.query.orderid * 1,
                    form_token:randomString()   
                })
                .then(res=>{
                    if(res.code==200){
                        Notify({ type: 'success', message: res.msg});
                        this.$router.go(-1);
                    }
                })
            }
        },
        clicktodo(){
            this.$refs.download.click();
        },
        uploadFile(){
            // 修改头像 
            this.file = this.$refs.download.files[0];
            let formData = new FormData();
            formData.append('head',this.file);
            this.$ajax.uploaduserhead(formData)
            .then(res=>{
                if(res.code==200){
                    this.show_pic = [...this.show_pic,res.data.file_path]
                }
            })
      
      },
    },
    mounted(){
     // 商品数据
    //   this.$ajax.productdetail({
    //     id:this.$route.query.gid,
    //     qishu:this.$route.query.qishu,
    //   })
    //   .then(res=>{
    //     if(res.code==200){
    //         this.obj = res.data.product;
    //     }
    //   })
        this.obj = JSON.parse(localStorage.getItem("goodonedetail"))
    }
}
</script>

